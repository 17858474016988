<template>
  <div class="main" :style="image">
    <div class="buttons">
      <img
        @click="goto({ name: 'Page', params: { id: 'officier' } })"
        :src="img.secondary.officier"
        draggable="false" 
      />
      <img
        @click="goto({ name: 'Page', params: { id: 'candidature' } })"
        :src="img.secondary.candidature"
        draggable="false" 
      />
    </div>
    <div class="retour">
      <img 
      @click="goHome"
        :src="img.secondary.retour"
        draggable="false" 
      />
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      img: {
        main: require("@/assets/menus/accueil.png"),
        secondary: {
          officier: require("@/assets/menus/Group 10.png"),
          candidature: require("@/assets/menus/Group 10.png"),
          retour: require("@/assets/tests/retour.png"),
        },
      },
    };
  },
  beforeMount() {},
  mounted() {},
  methods: {
    goto(route) {
      this.drawer = false;
      this.$routeToLoad.set(route);
      this.$router.push(route)
      // document.dispatchEvent(new CustomEvent("loadroute"));
    },
    goHome() {
      this.$routeToLoad.set({ name: "Home" });
      this.$router.push({ name: "Home" })
      // document.dispatchEvent(new CustomEvent("loadroute"));
    },
  },
  watch: {},
  computed: {
    image() {
      return `background-image:url(${this.img.main})`;
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  background-position-y: 100%;
  position: relative;
}

.buttons {
  position: absolute;
  bottom: 113px;
  // bottom: 97px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 124px;
}

.retour{
  position: absolute;
  left: 86px;
  z-index: 100;
  bottom: 770px;
}
</style>
