<template>
  <div class="container">
    <iframe :src="src" type="application/pdf" class="pdf" scrolling="no"></iframe>
    <div class="iframe-mask" @click.prevent.stop></div>
    <div class="iframe-mask2" @click.prevent.stop></div>
  </div>
</template>

<script>



export default {
  data() {
    return {
      // pdf: require("@/assets/tests/document.pdf"),
      // pdf2: './document.pdf#toolbar=0&navpanes=0&scrollbar=0&zoom=221&scrollbar=0',
      // pdf3: './document.pdf',
    };
  },
  props: {
    url: {
      required: true,
      type: String,
      default: ""
    },
    height: {
      required: true,
      type: String,
      default: "100%"
    },
    zoom: {
      required: true,
      type: Number,
      default: 221
    }
  },
  computed: {
    src(){
      return this.url + "&zoom="+ this.zoom
    }
  }
};
</script>


<style lang="scss" scoped>
.container{
    width: 100%;
    // height: 100%;
    height: 101%;
    // overflow-y: scroll;
    overflow-x: hidden;
    padding: 0;
    
    iframe{
        // height: 190%;
        width: 100%;
        height: 99%;
    }

    .iframe-mask{
        width: 97%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: red;
        opacity: 0;
        pointer-events: none;
        
    }
    .iframe-mask2{
        width: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: blue;
        opacity: 0;
        z-index: 1;
        pointer-events: none;
    }


     &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-track {
        background: white;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #3A4592;
        // border-radius: 10px;
        // border: 5px solid #3A4592;
    }
}
</style>