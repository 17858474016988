<template>
  <div class="main" :style="image">
    <modal :shiftX="0.28" ref="modal" :clickToClose="false" :width="450" :height="360" name="hello-world" :draggable="'.dragElement'">
       <!-- <button v-on:click="hide" style="margin: 13px 15px 0 0;float: right"><img :src="require('@/assets/img/kb-close.png')" draggable="false" height="30"/></button>  
      <button class="dragElement" style="margin: 10px 15px 0 0;float: right"><img :src="require('@/assets/img/kb-drag.png')" height="35" draggable="false" /></button>  
      <Keyboard @type="type" @bksp="bksp" @enter="enter" ref="keyboard" :mailKeys="mailKeys"></Keyboard> -->
        <div class="kb-drag dragElement" style="width: 100%;height: 100%; position: absolute;z-index: -1;"></div>
      <button v-on:click="hide" style="margin: 15px 15px 0 0;float: right;z-index: 10;"><img :src="require('@/assets/img/kb-close.png')" draggable="false" height="30"/></button>  
      <button style="margin: 10px 15px 0 0;float: right;pointer-events: none;"><img :src="require('@/assets/img/kb-drag.png')" height="35" draggable="false" /></button>  
      <Keyboard @type="type" @bksp="bksp" @enter="enter" style="z-index: 10;margin: 15px;padding: 5px; width: calc(100% - 30px);padding-top: 20px" ref="keyboard" :mailKeys="mailKeys"></Keyboard>
    
    </modal>
    <div class="page-title">Contact représentants des usagers</div>
    <div class="retour">
      <img 
      @click="goHome"
        :src="img.secondary.retour"
        draggable="false" 
      />
    </div>
    <div class="page-content page6">
      <div class="mainImg">
      <img 
        :src="img.secondary.image"
        draggable="false" 
      />
      </div>

      <div class="secondaryText">
        <div>
          <span>À voir aussi : </span>
          <span @click="goto('RepresentantsUsagers')">Je suis patient, qui me représente ? </span></div>
      </div>
      <img 
        class="mainTxt"
        :src="img.secondary.texte"
        draggable="false" 
      />

      <div class="contact-container">
        <div class="contact-content">
          <div>
            <div ref="nom" @animationend="reset" class="left">
              <label for="">Nom</label>
              <input ref="input_n" name="nom" type="text" @blur="loseFocus" placeholder="Votre nom"/>
            </div>
            <div ref="prenom" @animationend="reset" class="right">
              <label for="">Prénom</label>
              <input ref="input_p" name="prenom" type="text" @blur="loseFocus" placeholder="Votre prénom"/>
            </div>
          </div>
          <div ref="mail" @animationend="reset">
            <label for="">Mail</label>
              <input ref="input_m" name="mail" type="text" @blur="loseFocus" placeholder="Adresse mail"/>
          </div>
          <div ref="objet" @animationend="reset">
            <label for="">Objet</label>
              <input ref="input_o" name="objet" type="text" @blur="loseFocus" placeholder="Objet du message"/>
          </div>
          <div ref="comment" @animationend="reset">
            <label for="">Message</label>
              <textarea ref="input_c" name="message" rows="4" @blur="loseFocus" placeholder="Description"></textarea>
          </div>
          <div>
            <div class="send">
              <button @click="send">Envoyer</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import axios from "axios";
import Keyboard from "@/components/Keyboard"

export default {
  components: {
    Keyboard
  },
  data() {
    return {
      lastElementClicked: null,
      focusedInput: null,
      focused: false,
      flagPreventLoop: false,
      mailKeys: false,
      img: {
        main: require("@/assets/pages/bg5.png"),
        secondary: {
          image: require("@/assets/pages/img6.png"),
          texte: require("@/assets/pages/txt6.png"),
          retour: require("@/assets/tests/retour.png"),
        },
      },
    };
  },
  beforeMount() {},
  mounted() {
    document.addEventListener('mousedown', this.updateTarget);
    this.$refs["input_n"].addEventListener("focus", this.setFocus)
    this.$refs["input_p"].addEventListener("focus", this.setFocus)
    this.$refs["input_c"].addEventListener("focus", this.setFocus)
    this.$refs["input_m"].addEventListener("focus", this.setFocus)
    this.$refs["input_o"].addEventListener("focus", this.setFocus)
  },
  beforeDestroy(){
    document.removeEventListener('mousedown', this.updateTarget);
    this.$refs["input_n"].removeEventListener("focus", this.setFocus)
    this.$refs["input_p"].removeEventListener("focus", this.setFocus)
    this.$refs["input_c"].removeEventListener("focus", this.setFocus)
    this.$refs["input_m"].removeEventListener("focus", this.setFocus)
    this.$refs["input_o"].removeEventListener("focus", this.setFocus)
  },
  methods: {
    updateTarget(event){
      this.lastElementClicked = event.target;
    },
    show : function() {
      this.$modal.show('hello-world');
    },
    hide : function () {
      this.$modal.hide('hello-world');
    },
    goto(route) {
      this.drawer = false;
      this.$routeToLoad.set(route);
      this.$router.push(route)
      // document.dispatchEvent(new CustomEvent("loadroute"));
    },
    goHome() {
      this.$routeToLoad.set({ name: "Home" });
      this.$router.push({ name: "Home" })
      // document.dispatchEvent(new CustomEvent("loadroute"));
    },
    reset(el) {
      console.log(el.target)
      setTimeout(() => el.target.parentElement.classList.remove("input_error"), 1000);
    },
    send() {
      console.log(this.$refs)
      let nom = this.$refs.nom.querySelector('input').value
      let prenom = this.$refs.prenom.querySelector('input').value
      let mail = this.$refs.mail.querySelector('input').value
      let objet = this.$refs.objet.querySelector('input').value
      let comment = this.$refs.comment.querySelector('textarea').value
      if (!nom || !prenom || !comment) {
        if(!nom) this.$refs.nom.classList.add("input_error");
        if(!prenom) this.$refs.prenom.classList.add("input_error");
        if(!comment) this.$refs.comment.classList.add("input_error");
        // if(!mail) this.$refs.mail.classList.add("input_error");
        // if(!objet) this.$refs.objet.classList.add("input_error");
      } else {
        axios
          .post("http://emailmaymard:8010/api.php", {
            action: "contact",
            mail: mail,
            nom: nom,
            prenom: prenom,
            objet: objet,
            message: comment,
          })
          .then(function (res) {
            console.log(res);
          })
          .catch(function (error) {
            if (!error.status) {
              //https://github.com/axios/axios/issues/383#issuecomment-234079506
              console.log("error");
            }
          });
      }
    },
    setFocus(e){
      if(this.$refs.keyboard)
        this.$refs.keyboard.needMailKeys( (e.target.name === "mail") )
      else 
        this.mailKeys = (e.target.name === "mail")

      if(this.$refs.modal && this.$refs.modal.visible) return

      this.$refs.modal.open()
      // console.log(e.target.placeholder + " gained focus")
      this.focusedInput = e.target

      if(document.activeElement != e.target && !this.flagPreventLoop){
        e.target.removeEventListener("focus", this.setFocus)
        e.target.focus()
        e.target.addEventListener("focus", this.setFocus)
      }
      else if( this.flagPreventLoop){
        this.flagPreventLoop = false
      }
    },
    loseFocus(e){
      // console.log(e)
      // console.log(this.lastElementClicked)
      // console.log(this.lastElementClicked.classList)
      if(this.lastElementClicked && (
        this.lastElementClicked.classList.contains('vm--modal') ||
        this.lastElementClicked.classList.contains('simple-keyboard') ||
        this.lastElementClicked.classList.contains('hg-button') ||
        this.lastElementClicked.classList.contains('hg-rows') ||
        this.lastElementClicked.classList.contains('hg-row')
        )
      ) {
        e.target.removeEventListener("focus", this.setFocus)
        e.target.focus()
        e.target.addEventListener("focus", this.setFocus)
      }
      else{
        this.checkUnfocus(e.target)
        // console.log(e.target.placeholder + "lost focus")
        this.focusedInput = e.target
      }
      
    },
    checkUnfocus(element) {
      let t = this
      setTimeout(() => {
        // console.log(document.activeElement)
        if(!document.activeElement || document.activeElement === document.body){
          t.unfocus(element, true)
        }
      }, 0);
    },
    unfocus(target, value){
      if(value) this.focusedInput = null
    },
    type(char){
      if(this.focusedInput) {
        const selectionStart = this.focusedInput.selectionStart;
        const selectionEnd = this.focusedInput.selectionEnd;
        const currentValue = this.focusedInput.value;
        const newValue = currentValue.slice(0, selectionStart) + char + currentValue.slice(selectionEnd);
        this.focusedInput.value = newValue;
        const newCaretPosition = selectionStart + char.length;
        // this.focusedInput.setSelectionRange(newCaretPosition, newCaretPosition);
        this.focusedInput.setSelectionRange(Math.min(newCaretPosition, newValue.length), Math.min(newCaretPosition, newValue.length));


        // this.focusedInput.value += char
        this.focusedInput.focus()
      }
    },
    bksp() {
      if(this.focusedInput) {

        const selectionStart = this.focusedInput.selectionStart;
        const selectionEnd = this.focusedInput.selectionEnd;
        const currentValue = this.focusedInput.value;

        if (selectionStart === selectionEnd && selectionStart > 0) {
          const newValue = currentValue.slice(0, selectionStart - 1) + currentValue.slice(selectionEnd);
          this.focusedInput.value = newValue;
          const newCaretPosition = selectionStart - 1;
          this.focusedInput.setSelectionRange(Math.max(newCaretPosition, 0), Math.max(newCaretPosition, 0));
        }
      }
    },
    enter() {
      if (this.focusedInput && this.focusedInput.tagName.toLowerCase() === 'textarea') {
        const selectionStart = this.focusedInput.selectionStart;
        const selectionEnd = this.focusedInput.selectionEnd;
        const currentValue = this.focusedInput.value;

        const newValue = currentValue.slice(0, selectionStart) + '\n' + currentValue.slice(selectionEnd);
        this.focusedInput.value = newValue;
        const newCaretPosition = selectionStart + 1;
        this.focusedInput.setSelectionRange(newCaretPosition, newCaretPosition);
      }
    }
  },
  watch: {},
  computed: {
    image() {
      return `background-image:url(${this.img.main})`;
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  background-position-y: 100%;
  position: relative;
}

.buttons {
  position: absolute;
  bottom: 113px;
  // bottom: 97px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 124px;
}

.retour{
  position: absolute;
  left: 45px;
  z-index: 100;
  bottom: 770px;
}

.page-title{
  position: absolute;
  bottom: 833px;
  align-content: center;
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 106%;

  color: #3A4592;
  z-index: 10;
  font-family: 'Poppins' !important;
  // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.mainImg img{
  width: 642px;
  height: 642px;
  position: absolute;
  left: 223px;
  top: 151px;
}
.mainTxt{
  position: absolute;
  right: 251px;
  bottom: 45px;
}
.secondaryText{
  position: absolute;
  bottom: 49px;
  
  z-index: 3;
  left: 250px;


  span{
    font-family: 'Helvetica' !important;
    font-size: 25px;
    color: #3A4592;
  }
  span:nth-child(2){
    text-decoration: underline;
    
    color: #D27B27;
    font-weight: bold;
  }
}


.contact-container{
  position: fixed;
  height: 605px;
  width: 632px;
  background: white;
  box-shadow: -7px 7px 28.2px 6px rgba(58, 69, 146, 0.2);
  border-radius: 10px;
  bottom: 170px;
  right: calc(-316px + 255px);
  transform: translate(-50%);
  padding: 20px 76px 25px 71px;

  color: #3A4592;
  font-family: 'Helvetica' !important;
  font-style: normal;

  .contact-content{
    font-weight: 700;
    font-size: 20px;
    line-height: 128%;
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 15px;

    div{
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      position: relative;
      .left, .right{
        width: 50%;
      }
      .left{
        padding-right: 15px;
      }
      .right{
        padding-left: 15px;
      }
      label{
        margin-bottom: 14px;
        font-size: 20px;
      }
      input, textarea{
        font-weight: 600;
        width: 100%;
        padding: 15px 22px;
        font-size: 18px;
        line-height: 128%;
        background: #EAEFF8;
        border-radius: 15px;
        resize: none;
        border: solid 1px transparent;

          color: #3A4592;

        &::placeholder, ::-webkit-input-placeholder{
          font-weight: 400 !important;
          font-size: 18px !important;
          font-family: 'Helvetica' !important;
          line-height: 128%;
          
          color: #9299C5;
        }
      }
      &.input_error {
        input,
        textarea {
          animation: shake 0.2s ease-in-out 0s 2;
          border: solid 1px #c60a0a;
          position: relative;
        }
        &::before {
          content: "Obligatoire";
          font-family: "Helvetica";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 128%;
          position: absolute;
          color: #c60a0a;
          top: 100%;
          left: 0;
        }
      }

      &.send{
        display: flex;
        justify-content: center;
        button{
          color: white;
          background: #D27B27;
          font-weight: 700;
          font-size: 20px;
          line-height: 128%;
          text-transform: uppercase;
          padding: 18px 30px;
          text-align: center;
          border-radius: 10px;
        }
      }
    }
  }
}

@keyframes shake {
  0% {
    margin-left: 0rem;
  }
  25% {
    margin-left: 0.5rem;
  }
  75% {
    margin-left: -0.5rem;
  }
  100% {
    margin-left: 0rem;
  }
}

</style>
