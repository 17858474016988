<template>
  <div class="text-center">
    <div @touchstart="clickSecret('top')" @touchend="/*unset('top')*/null" @mouseup="/*unset('top')*/null" @mousedown="clickSecret('top')" class="secret top"></div>
    <div @touchstart="clickSecret('bottom')" @touchend="/*unset('bottom')*/null" @mouseup="/*unset('bottom')*/null" @mousedown="clickSecret('bottom')" class="secret bottom"></div>
    <v-dialog v-model="dialog" width="500">
      <v-card class="pb-3">
        <v-card-title class="text-h5 px-3 grey lighten-2"> Admin </v-card-title>
        <div style="padding: 20px 120px">
          <v-otp-input
            v-show="!logged"
            :name="`username_${Math.random()}`"
            autocomplete="off"
            @finish="checkpwd"
            v-model="password"
            length="4"
          ></v-otp-input>


          <v-icon v-show="logged" @click="dialog = false" style="font-size: 50px">mdi-reply</v-icon>
          <div v-show="logged" style="display:flex;flex-direction:column">
            <v-slider
              label="Timer veille (min)"
              max="10"
              min="1"
              value="2"
              thumb-label="always"
            ></v-slider>
            <v-divider></v-divider>
            <v-btn :color="certainToQuit? 'error': 'primary'" @click="quit">
              Fermer App
            </v-btn>
          </div>
          <div v-show="!logged" ref="keyboard" style="padding: 20px 20px;background:white" class="simple-keyboard"></div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";

// const {ipcRenderer} = require('electron');

export default {
  data() {
    return {
      logged: false,
      keyboard: null,
      password: "",
      dialog: false,
      clicked: {
        top: false,
        bottom: false,
      },
      delay: 15000,
      timeout: null,
      clickedInTime: false,
      certainToQuit: false,
    };
  },
  mounted() {
  },
  methods: {
    onChange(input) {
      this.password = input;
      if (this.password.length >= 4) this.checkpwd();
      // console.log("Input changed", input);
    },
    onKeyPress(button) {
      // console.log("Button pressed", button);
    },
    unset(area){
      this.clicked[area] = false
      // console.log("unset "+area)
    },
    clickSecret(area) {
      // console.log("set "+area)
      if (area == "top" && this.clicked.bottom && this.clickedInTime)
        // this.quit()// this.openPanel();
        this.openPanel();
      else if (area == "bottom" && this.clicked.top && this.clickedInTime)
        this.openPanel();
        // this.quit() //this.openPanel();
      else {
        let t = this;
        this.clicked[area] = true;
        this.clickedInTime = true;
        this.timeout = setTimeout(() => {
          t.clickedInTime = false;
          t.clicked.top = false;
          t.clicked.bottom = false;
        }, this.delay);
      }
      // console.log(this.clicked);
    },
    checkpwd() {
      if (this.password === "1111") {
        this.password = "";
        // this.keyboard.input.default = "";
        this.keyboard.setInput("");
        this.logged = true
      } else {
        this.password = "";
        // this.keyboard.input.default = "";
        this.keyboard.setInput("");
        this.logged = false
      }
    },
    openPanel() {
      if (this.timeout) this.stopTimeout();
      this.dialog = true;
    },
    stopTimeout() {
      clearTimeout(this.timeout);
      this.clicked.top = false;
      this.clicked.bottom = false;
    },
    quit() {
      window.close()
      return
      if(!this.certainToQuit) {
        this.certainToQuit = true
        return
      }
      else{
        window.close();
        // ipcRenderer.send('close-me')
      }
      
    },
  },
  watch: {
    dialog(){
      if(this.dialog == false) {
        this.logged = false
      }
      else{
        if (!this.keyboard){
          let t = this

          setTimeout( () => {
            t.keyboard = new Keyboard({
              layout: {
                'default': [
                  '1 2 3',
                  '4 5 6',
                  '7 8 9'
                ]
              },
              onChange: (input) => t.onChange(input),
              onKeyPress: (button) => t.onKeyPress(button),
            });
          }, 150)
          
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.secret {
  width: 40px;
  height: 40px;
  background: blue;
  z-index: 99999;
  position: fixed;
}
.top {
  top: 5px;
  left: 5px;
}

.bottom {
  bottom: 5px;
  right: 5px;
}
</style>
