<template>
  <div class="main" :style="image">
    <modal :shiftX="0.9" ref="modal" :clickToClose="false" :width="450" :height="360" name="hello-world" :draggable="'.dragElement'">
       <!-- <button v-on:click="hide" style="margin: 15px 15px 0 0;float: right"><img :src="require('@/assets/img/kb-close.png')" draggable="false" height="30"/></button>  
      <button class="dragElement" style="margin: 10px 15px 0 0;float: right"><img :src="require('@/assets/img/kb-drag.png')"  height="35" draggable="false" /></button>  
      <Keyboard @type="type" @bksp="bksp" @enter="enter"></Keyboard> -->
      <div class="kb-drag dragElement" style="width: 100%;height: 100%; position: absolute;z-index: -1;"></div>
      <button v-on:click="hide" style="margin: 15px 15px 0 0;float: right;z-index: 10;"><img :src="require('@/assets/img/kb-close.png')" draggable="false" height="30"/></button>  
      <button style="margin: 10px 15px 0 0;float: right;pointer-events: none;"><img :src="require('@/assets/img/kb-drag.png')" height="35" draggable="false" /></button>  
      <Keyboard @type="type" @bksp="bksp" @enter="enter" style="z-index: 10;margin: 15px;padding: 5px; width: calc(100% - 30px);padding-top: 20px"></Keyboard>
    </modal>
    <div class="retour">
      <img @click="goHome" :src="img.secondary.retour" draggable="false" />
    </div>
    <div class="page-content page7">
      <img class="mainTxt" :src="img.secondary.texte" draggable="false" />
      <div class="form">
        <div class="dots" ref="dots">
            <div class="loading loading-0"></div>
            <div class="loading loading-1"></div>
            <div class="loading loading-2"></div>
        </div>
        <div ref="formwrapper" class="form-wrapper" @transitionend="onTransiEnd">
          <div ref="formcontainer" class="container">
            <div class="line">
              <div ref="nom" class="nom left" @animationend="reset">
                <label for="">Nom</label>
                <input ref="input_n" type="text" @blur="loseFocus" placeholder="Votre nom (facultatif)" />
              </div>
              <div ref="prenom" class="prenom right" @animationend="reset">
                <label for="">Prénom</label>
                <input ref="input_p" type="text" @blur="loseFocus" placeholder="Votre prénom (facultatif)" />
              </div>
            </div>
            <div class="line avis">
              <label for="">Avis</label>
              <Stars ref="stars"></Stars>
            </div>
            <div class="line">
              <div ref="comment" style="width: 100%" @animationend="reset">
                <label for="">Commentaire</label>
                <textarea ref="input_c" rows="5" @blur="loseFocus" placeholder="Description"></textarea>
              </div>
            </div>
            <div class="send">
              <button @click="send">Envoyer</button>
            </div>

            <div class="valid-overlay">
              <div class="top">
                <svg class="checkmark" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                 <g stroke="#56A85F" transform="translate(-2, 0.1)" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                    <path ref="checkmark" d="M6.5 13.5L10 17 l8.808621-8.308621"/>
                  </g>
                  <g class="checkmark cherckmark-shadow" stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                    <path ref="checkmarkshadow" d="M6.5 13.5L10 17 l8.808621-8.308621"/>
                  </g>
                </svg>
                <img
                  class="close"
                  :src="require('@/assets/img/close.svg')"
                  @click="resetForm"
                />
              </div>
              <div class="bottom">
                <div>Merci pour votre avis !</div>
                <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. adipiscing Lorem ipsum</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";

import Stars from "@/components/Star.vue";
import Keyboard from "@/components/Keyboard"

export default {
  components: {
    Stars,
    Keyboard
  },
  data() {
    return {
      lastElementClicked: null,
      focusedInput: null,
      focused: false,
      flagPreventLoop: false,
      format: "video/mp4",
      ready: false,
      img: {
        main: require("@/assets/pages/bg7.png"),
        secondary: {
          texte: require("@/assets/pages/txt7.png"),
          retour: require("@/assets/tests/retour.png"),
        },
      },
    };
  },
  beforeMount() {},
  mounted() {
    document.addEventListener('mousedown', this.updateTarget);
    this.$refs["input_n"].addEventListener("focus", this.setFocus)
    this.$refs["input_p"].addEventListener("focus", this.setFocus)
    this.$refs["input_c"].addEventListener("focus", this.setFocus)

    this.$refs.stars.setRating(1)
  },
  beforeDestroy(){
    document.removeEventListener('mousedown', this.updateTarget);
    this.$refs["input_n"].removeEventListener("focus", this.setFocus)
    this.$refs["input_p"].removeEventListener("focus", this.setFocus)
    this.$refs["input_c"].removeEventListener("focus", this.setFocus)
  },
  methods: {
    updateTarget(event){
      this.lastElementClicked = event.target;
    },
    show : function() {
      this.$modal.show('hello-world');
    },
    hide : function () {
      this.$modal.hide('hello-world');
    },
    resetForm() {
      this.$refs.formcontainer.classList.remove("valid");
      this.$refs["input_n"].value = ""
      this.$refs["input_p"].value = ""
      this.$refs["input_c"].value = ""
      // this.$refs.stars.setRating(1)
    },
    goto(route) {
      this.drawer = false;
      this.$routeToLoad.set(route);
      this.$router.push(route);
      // document.dispatchEvent(new CustomEvent("loadroute"));
    },
    goHome() {
      this.$routeToLoad.set({ name: "Home" });
      this.$router.push({ name: "Home" });
      // document.dispatchEvent(new CustomEvent("loadroute"));
    },
    reset(el) {
      console.log(el.target);
      setTimeout(
        () => el.target.parentElement.classList.remove("input_error"),
        1000
      );
    },
    onTransiEnd(e){
      console.log(e)
      if(this.$refs.formcontainer.classList.contains("valid")){
        console.log('open')
        this.$refs.checkmark.classList.remove("tick-reverse")  
        this.$refs.checkmarkshadow.classList.remove("tick-reverse")  
        this.$refs.checkmark.classList.add("tick")  
        this.$refs.checkmarkshadow.classList.add("tick")  
      }
      else{
        console.log('close')
        this.$refs.checkmark.classList.add("tick-reverse")
        this.$refs.checkmarkshadow.classList.add("tick-reverse")
        this.$refs.checkmark.classList.remove("tick")
        this.$refs.checkmarkshadow.classList.remove("tick")
      }
    },
    send() {
      let t = this
      //   t.$refs.formwrapper.classList.add("sending");
      // setTimeout(() => {
      //   t.$refs.formwrapper.classList.remove("sending");
      //   t.$refs.formcontainer.classList.add("valid");
      // }, 2500)
      // return
      console.log(this.$refs);
      let nom = this.$refs.nom.querySelector("input").value;
      let prenom = this.$refs.prenom.querySelector("input").value;
      let rating = this.$refs.stars.getRating();
      let comment = this.$refs.comment.querySelector("textarea").value;
      
      if (!comment) {
        this.$refs.comment.classList.add("input_error");
      } 
      else {
        t.$refs.formwrapper.classList.add("sending");
        axios
          .post("http://emailmaymard:8010/api.php", {
            action: "avis",
            nom: nom,
            prenom: prenom,
            rating: rating,
            message: comment,
          })
          .then(function (res) {
            console.log(res);
            // t.$refs.formcontainer.classList.add("valid");
             setTimeout(() => {
              t.$refs.formwrapper.classList.remove("sending");
              t.$refs.formcontainer.classList.add("valid");
            }, 1000)
          })
          .catch(function (error) {
            if (!error.status) {
              //https://github.com/axios/axios/issues/383#issuecomment-234079506
              console.log("error");
            }
          });
      }
    },

    setFocus(e){
      this.$refs.modal.open()
      // console.log(e.target.placeholder + " gained focus")
      this.focusedInput = e.target

      if(document.activeElement != e.target && !this.flagPreventLoop){
        e.target.removeEventListener("focus", this.setFocus)
        e.target.focus()
        e.target.addEventListener("focus", this.setFocus)
      }
      else if( this.flagPreventLoop){
        this.flagPreventLoop = false
      }
    },
    loseFocus(e){
      // console.log(e)
      // console.log(this.lastElementClicked)
      // console.log(this.lastElementClicked.classList)
      if(this.lastElementClicked && (
        this.lastElementClicked.classList.contains('vm--modal') ||
        this.lastElementClicked.classList.contains('simple-keyboard') ||
        this.lastElementClicked.classList.contains('hg-button') ||
        this.lastElementClicked.classList.contains('hg-rows') ||
        this.lastElementClicked.classList.contains('hg-row')
        )
      ) {
        e.target.removeEventListener("focus", this.setFocus)
        e.target.focus()
        e.target.addEventListener("focus", this.setFocus)
      }
      else{
        this.checkUnfocus(e.target)
        // console.log(e.target.placeholder + "lost focus")
        this.focusedInput = e.target
      }
      
    },
    checkUnfocus(element) {
      let t = this
      setTimeout(() => {
        // console.log(document.activeElement)
        if(!document.activeElement || document.activeElement === document.body){
          t.unfocus(element, true)
        }
      }, 0);
    },
    unfocus(target, value){
      if(value) this.focusedInput = null
    },
    type(char){
      if(this.focusedInput) {
        const selectionStart = this.focusedInput.selectionStart;
        const selectionEnd = this.focusedInput.selectionEnd;
        const currentValue = this.focusedInput.value;
        const newValue = currentValue.slice(0, selectionStart) + char + currentValue.slice(selectionEnd);
        this.focusedInput.value = newValue;
        const newCaretPosition = selectionStart + char.length;
        // this.focusedInput.setSelectionRange(newCaretPosition, newCaretPosition);
        this.focusedInput.setSelectionRange(Math.min(newCaretPosition, newValue.length), Math.min(newCaretPosition, newValue.length));


        // this.focusedInput.value += char
        this.focusedInput.focus()
      }
    },
    bksp() {
      if(this.focusedInput) {

        const selectionStart = this.focusedInput.selectionStart;
        const selectionEnd = this.focusedInput.selectionEnd;
        const currentValue = this.focusedInput.value;

        if (selectionStart === selectionEnd && selectionStart > 0) {
          const newValue = currentValue.slice(0, selectionStart - 1) + currentValue.slice(selectionEnd);
          this.focusedInput.value = newValue;
          const newCaretPosition = selectionStart - 1;
          this.focusedInput.setSelectionRange(Math.max(newCaretPosition, 0), Math.max(newCaretPosition, 0));
        }
      }
    },
    enter() {
      if (this.focusedInput && this.focusedInput.tagName.toLowerCase() === 'textarea') {
        const selectionStart = this.focusedInput.selectionStart;
        const selectionEnd = this.focusedInput.selectionEnd;
        const currentValue = this.focusedInput.value;

        const newValue = currentValue.slice(0, selectionStart) + '\n' + currentValue.slice(selectionEnd);
        this.focusedInput.value = newValue;
        const newCaretPosition = selectionStart + 1;
        this.focusedInput.setSelectionRange(newCaretPosition, newCaretPosition);
      }
    }
  },
  watch: {},
  computed: {
    image() {
      return `background-image:url(${this.img.main})`;
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  background-position-y: 100%;
  position: relative;
}

.buttons {
  position: absolute;
  bottom: 113px;
  // bottom: 97px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 124px;
}

.retour {
  position: absolute;
  left: 45px;
  z-index: 100;
  bottom: 770px;
}

.page-title {
  position: absolute;
  top: 10px;
  align-content: center;
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 106%;

  color: #3a4592;
  z-index: 30000;
}

.mainImg {
  position: absolute;
  left: 315px;
  top: 130px;
}
.mainTxt {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 73px;
}

.form {
  position: absolute;
  width: 652px;
  height: 640px;
  bottom: 63px;
  left: 50%;
  transform: translate(-50%);
  .dots{
    position: absolute;
    width: 250px;
    height: 50px;
    left: 50%;
    display: flex;
    justify-content: center;
    top: 50%;
    transform: translate(-50%, -50%);
    
  }
  .form-wrapper{
    width: 100%;
    height: 100%;

    .container {
      height: 562px;
      width: 100%;
      box-shadow: -1px 3px 23.8px rgba(58, 69, 146, 0.25);
      background: white;
      border-radius: 14%;
      position: relative;
      padding: 39px 89px 26px 78px;
      display: flex;
      flex-flow: column;
      gap: 15px;


      &:after {
        content: "";
        position: absolute;
        border: 1px solid white;
        border-color: white transparent;
        border-width: 77px 78px 0 78px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        bottom: -77px;
        left: 50%;
        transform: translate(-50%);
      }
      &:before {
        content: "";
        position: absolute;
        border: 1px solid white;
        border-color: #999 transparent;
        border-width: 78px 78px 0 78px;

        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        bottom: -78px;
        left: 50%;
        transform: translate(-50%);
      }
      label {
        font-family: "Helvetica";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 128%;
        color: #3a4592;
      }

      .line {
        width: 100%;
        display: flex;
        flex-flow: row wrap;

        .left,
        .right {
          width: 50%;
        }
        .left {
          padding-right: 15px;
        }
        .right {
          padding-left: 15px;
        }
        label {
          margin-bottom: 14px;
          font-size: 20px;
        }
        input,
        textarea {
          font-weight: 600;
          width: 100%;
          padding: 15px 22px;
          font-size: 18px;
          line-height: 128%;
          background: #eaeff8;
          border-radius: 15px;
          resize: none;
          color: #3A4592;
          border: solid 1px transparent;

          &::placeholder,
          ::-webkit-input-placeholder {
            font-weight: 400 !important;
            font-size: 18px !important;
            font-family: "Helvetica" !important;
            line-height: 128%;

            color: #9299c5;
          }
        }
        textarea {
          width: 100%;
        }

        &.avis {
          display: flex;
          flex-direction: column;
          .stars {
            display: flex;
            flex-direction: row;
            gap: 15px;
          }
        }

        div {
          position: relative;
          &.input_error {
            input,
            textarea {
              animation: shake 0.2s ease-in-out 0s 2;
              border: solid 1px #c60a0a;
              position: relative;
            }
            &::before {
              content: "Obligatoire";
              font-family: "Helvetica";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 128%;
              position: absolute;
              color: #c60a0a;
              top: 100%;
              left: 0;
            }
          }
        }
      }
      .send {
        display: flex;
        justify-content: center;
        button {
          color: white;
          background: #d27b27;
          font-weight: 700;
          font-size: 20px;
          line-height: 128%;
          text-transform: uppercase;
          padding: 18px 30px;
          text-align: center;
          border-radius: 10px;
        }
      }

      .valid-overlay {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 10;
        flex-direction: column;
        border-radius: 14%;
        overflow: hidden;

        div {
          height: 100%;
          width: 100%;
          position: relative;

          &.top {
            background: #79c681;

            .close {
              position: absolute;
              top: 39px;
              right: 49px;
              width: 33px;
              height: 33px;
            }
          }

          &.bottom {
            background: white;
            display: flex;
            width: 100%;
            padding: 55px 100px;
            text-align: center;

            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            div{
              font-family: 'Helvetica';
              font-style: normal;
              font-weight: 700;
              font-size: 30px;
              line-height: 128%;
              color: #3A4592;
            }
            div:nth-child(2){
              font-family: 'Helvetica' !important;
              font-weight: 400;
              font-size: 18px;
            }
          }
        }
      }

      &.valid .valid-overlay {
        display: flex;
      }
    }
  }
}

@keyframes shake {
  0% {
    margin-left: 0rem;
  }
  25% {
    margin-left: 0.5rem;
  }
  75% {
    margin-left: -0.5rem;
  }
  100% {
    margin-left: 0rem;
  }
}

.form-wrapper {
  transform: scaleY(1);
  transition: transform 0.6s cubic-bezier(0, 1.98, 0.83, 0.67);
  transform-origin: center bottom;
}

.form-wrapper {
  &.sending {
    transform: scaleY(0);
    transform-origin: center bottom;
    transition: transform 0.6s cubic-bezier(.17,.67,.22,.98);
  }
}


.checkmark {
  display: block;
  width: 100%;
  height: 281px;;
  color: white; /* SVG path use currentColor to inherit this */
}

.tick {
  stroke-dasharray: 18;
  stroke-dashoffset: 18;
  animation: draw 1s forwards 1s;
}

.tick-reverse {
  stroke-dasharray: 18 !important;
  stroke-dashoffset: 18 !important;
  animation: draw 0s reverse !important;
}

@keyframes draw { 
  from{ stroke-dashoffset: 18 }
  to { stroke-dashoffset: 0 } 
}



.loading{
  width:30px;
  height:30px;
  background:#FFF;
  border-radius:100%;
  float:left;
  margin-right:15px;
}
.loading-0{
    -webkit-animation:bounce 1s infinite;
    -webkit-animation-delay:.1s;
    background:#D9DFEF
  
}
.loading-1{
    -webkit-animation:bounce 1s infinite;
    -webkit-animation-delay:.3s;
    background:#D9DFEF;
}
.loading-2{
    -webkit-animation:bounce 1s infinite ease;
    -webkit-animation-delay:.5s;
    background:#D9DFEF;
}
@-webkit-keyframes bounce {
  0%, 100% {
    opacity:1;
  }
  60% {
    opacity:.0;
   
  }
}
</style>
