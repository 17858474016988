<template>
    <div @click="goto" class="button" :style="cssbtn">
        <img draggable="false" :src="img" :style="css">
        <div class="title" v-html="title" :style="csstxt">
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{

        }
    },
    methods: {
        goto(route){
            this.clickBtn()
            if(this.to == '') return
            this.$routeToLoad.set(this.to)
            this.$router.push(this.to)
            // document.dispatchEvent(new CustomEvent("loadroute"))
        },
        clickBtn(){
           this.$emit('btnclick') 
        }
    },
    props: {
        img: {
            required: true,
            type: String,
            default: ''
        },
        to: {
            required: true,
            type: String,
            default: ''
        },
        title: {
            required: true,
            type: String,
            default: ''
        },
        css: {
            default: ''
        },
        cssbtn: {
            default: ''
        },
        csstxt: {
            default: ''
        }
    }
}
</script>


<style lang="scss" scoped>

.button{
    border-radius: 15px;
    background: white;
    cursor: pointer;
    width: 251px;
    height: 251px;
    position: relative;

    img{
        position: absolute;
        left: 50%;
        top: 35px;
        transform: translate( -50%);

    }

    .title{
        font-weight: 700;
        font-size: 20px;
        color: #3A4592;
        line-height: 26px;

        position: absolute;
        bottom: 24px;
        top: 175px;
        width: 100%;
        padding: 0 6px 0 13px;
        text-align: center;
        align-content: center;
    }
}

</style>