<template>
  <v-app toolbar>
    <div v-show="show"><WaitingScreen ref="waitingScreen" @quit="hideWaitingScreen"></WaitingScreen></div>
    <Header></Header>
    <v-main class="main">
      <!-- <router-view v-slot="{ Component }" >
        <transition name="slide">
          <component :is="Component" :key="$route.path"  />
        </transition>
      </router-view> -->
      <transition :name='transitionMode_' mode="out-in">
        <router-view :key="$route.fullPath"/>
      </transition>
      <!-- <router-view :key="$route.fullPath" ></router-view> -->
      <button v-show="showBtn && prevIsRoute" @click="goto(prevRoute, 'slide-x-reverse-transition')" class="button-prev">
        <img draggable="false" :src="require('@/assets/tests/prev.png')" />
      </button>
      <button v-show="showBtn && nextIsRoute" @click="goto(nextRoute, 'slide-x-transition')" class="button-next">
        <img draggable="false" :src="require('@/assets/tests/next.png')" />
      </button>
      <Loader></Loader>
    </v-main>
  </v-app>
</template>

<script>
import Header from "@/components/Header.vue";
import Loader from "@/components/Loader.vue";
import WaitingScreen from "@/views/WaitingScreen.vue";

export default {
  components: {
    Header,
    Loader,
    WaitingScreen
  },
  data() {
    return {
      show: false,
      transitionMode: '',
      prevRoute: null,
      nextRoute: null,
      maxTime: 12000 * 1000,
      timer: null
    };
  },
  mounted(){
    this.updateNavigation();
    document.addEventListener("updatenav", this.updateNavigation);
    document.addEventListener('click', this.resetTimer)

    this.resetTimer()
  },
  beforeDestroy(){
    document.removeEventListener("updatenav", this.updateNavigation);
    document.removeEventListener("click", this.resetTimer);
  },
  methods: {
    hideWaitingScreen(){
      this.show = false
    },
    startTimer(){
      let t = this
      this.timer = setTimeout(() => {
        // if(t.$route.name != 'WaitingScreen'){
        //   t.$router.push({name: 'WaitingScreen'})
        // }
        t.show = true
        t.$refs.waitingScreen.ready = true
      }, this.maxTime)
    },
    resetTimer(){
      if(this.timer)
      {
        clearTimeout(this.timer)
        this.startTimer()
      }
      else this.startTimer()
    },
    goto(path, transitionMode) {
      this.transitionMode = transitionMode
      let t = this
      t.$router.push(path).then(() => {t.transitionMode = ''});
      t.$routeToLoad.set(path)
      
      // document.dispatchEvent(new CustomEvent("loadroute"))
    },
    updateNavigation(){
      this.prevRoute = this.$navigation.prev.get()
      this.nextRoute = this.$navigation.next.get()
      // console.log("navbtns updated")
    }
  },
  computed: {
    transitionMode_(){
      return this.transitionMode
    },
    prevIsRoute(){
      return this.prevRoute != null
    },
    nextIsRoute(){
      return this.nextRoute != null
    },
    showBtn() {
      return (
        this.$route.name != "Home" &&
        this.$route.name != "JoinUs" &&
        this.$route.name != "Discover" &&
        this.$route.name != "Company" &&
        this.$route.name != "WaitingScreen"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background-repeat: no-repeat;

  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

main {
  overflow: hidden !important;
  position: absolute;
  width: 100vw;
  height: 941px;
  margin-top: 139px;
}

.button-prev {
  width: 91px;
  height: 91px;
  z-index: 8;
  position: fixed;
  left: 62px;
  bottom: 59px;
}
.button-next {
  width: 91px;
  height: 91px;
  z-index: 15;
  position: fixed;
  right: 71px;
  bottom: 59px;
}
</style>


<style lang="scss">
html{
  overflow: hidden;
}

*{
  font-family: 'Poppins' !important;
}

input:focus-visible{
  outline-color: #3A4592 !important;
}


.slide-enter-active,
.slide-leave-active {
  transition: all 0.75s ease-out;
}

.slide-enter-to {
  position: absolute;
  right: 0;
}

.slide-enter-from {
  position: absolute;
  right: -100%;
}

.slide-leave-to {
  position: absolute;
  left: -100%;
}

.slide-leave-from {
  position: absolute;
  left: 0;
}



@font-face {
    font-family: 'Poppins';
    font-weight: normal;
    src: url(./../assets/font/Poppins/Poppins-Regular.ttf) format('truetype');
  }
@font-face {
    font-family: 'Poppins';
    font-weight: bolder;
    src: url(./../assets/font/Poppins/Poppins-Bold.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Poppins';
    font-weight: lighter;
    src: url(./../assets/font/Poppins/Poppins-Thin.ttf) format('truetype');
  }
@font-face {
    font-family: 'Poppins';
    font-weight: bold;
    src: url(./../assets/font/Poppins/Poppins-SemiBold.ttf) format('truetype');
  }
@font-face {
    font-family: 'Poppins-Regular';
    src: url(./../assets/font/Poppins/Poppins-Regular.ttf) format('truetype');
  }
  
@font-face {
    font-family: 'Helvetica';
    font-weight: normal;
    src: url(./../assets/font/helvetica/Helvetica.ttf) format('truetype');
  }
@font-face {
    font-family: 'Helvetica';
    font-weight: bold;
    src: url(./../assets/font/helvetica/Helvetica-Bold.ttf) format('truetype');
  }
/* @font-face {
    font-family: 'Helvetica';
    font-weight: lighter;
    src: url(./../assets/font/helvetica/Helvetica-light.ttf);
  } */

.v-app-bar{
  box-shadow: unset !important;
}

/* 
.page-content .mainPoster::after{
  content: "";
  width: 100px;
  height: 100px;
  display: block;
  position: absolute;
  top: calc(50% - 49px);
  left:  calc(50% - 49px);
  background-image: url(./../assets/img/playbtn.png) ;
} */

.vm--container{
  pointer-events: none !important;
}
.vm--modal{
  pointer-events: all !important;
  //width: 615px !important;
  transform: scale(1.4) !important;
}
.vm--overlay{
  background: transparent !important;
  pointer-events: none !important;
}

.hg-theme-default{
  /* background: ; */
}

.hg-button{
  color: #3A4592 !important;
  font-weight: 600 !important;
}

iframe::-webkit-scrollbar {
     display: none; 
}




.vidpage-dimensions{
  top: 297px !important;
  width: 1238px !important;
}

.vjs-poster img{
  object-fit: cover !important;
  o-object-fit: cover !important;
}

.video-js .vjs-control-bar{
  height: 4.5rem !important;
  padding-left: 8px !important;
  gap: 8px !important;
  font-weight: 600 !important;
  z-index: 1001 !important;
}

.vjs-play-control .vjs-icon-placeholder::before, .vjs-mute-control .vjs-icon-placeholder::before {
  align-content: center !important;
  font-size: 3.6em !important;
}  

.vjs-time-control {
  align-content: center !important;
  font-size: 2em !important;
}  

.vjs-time-tooltip, .vjs-volume-tooltip{
  font-size: 1.2em !important;
}

.vjs-volume-panel-horizontal{
  align-items: center;
}
.video-js .vjs-volume-panel .vjs-volume-control{
  margin-left: 4px !important;
}

.vjs-volume-control .vjs-control .vjs-volume-horizontal div{
  width: 6em;
} 

.vjs-paused > span {
    width: 100%;
    height: 100%;
    background-image: url('./../assets/img/play.png') !important;
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: 50% ;
      &:before {
        content: "";
        display: none;
      }
      &:hover {
        background-color: transparent;
        opacity: .7;
      }
}

.vjs-playing > span {
    width: 100%;
    height: 100%;
    background-image: url('./../assets/img/pause.png') !important;
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: 50% ;
      &:before {
        content: "";
        display: none;
      }
      &:hover {
        background-color: transparent;
        opacity: .7;
      }
}

.vjs-mute-control > span {
    width: 100%;
    height: 100%;
    background-image: url('./../assets/img/sound.png') !important;
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: 50% ;
      &:before {
        content: "";
        display: none;
      }
      &:hover {
        background-color: transparent;
        opacity: .7;
      }
}

.vjs-vol-0 > span {
    width: 100%;
    height: 100%;
    background-image: url('./../assets/img/nosound.png') !important;
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: 50% ;
      &:before {
        content: "";
        display: none;
      }
      &:hover {
        background-color: transparent;
        opacity: .7;
      }
}

.video-js .vjs-play-progress:before{
  font-size: 0 !important;
}




</style>