<template>
  <svg ref="stars" viewBox="0 0 400 60" @mousedown="mousedown = true" @mouseleave="mousedown = false">
    <svg
        @click="setRating(1)"
        viewBox="160 0 64 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32 0L39.409 22.8024H63.3849L43.988 36.8951L51.3969 59.6976L32 45.6049L12.6031 59.6976L20.012 36.8951L0.615135 22.8024H24.591L32 0Z"
          fill="#EAEFF8"
        />
      </svg>
      <svg
        @click="setRating(2)"
        viewBox="80 0 64 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32 0L39.409 22.8024H63.3849L43.988 36.8951L51.3969 59.6976L32 45.6049L12.6031 59.6976L20.012 36.8951L0.615135 22.8024H24.591L32 0Z"
          fill="#EAEFF8"
        />
      </svg>
      <svg
        @click="setRating(3)"
        viewBox="00 0 64 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32 0L39.409 22.8024H63.3849L43.988 36.8951L51.3969 59.6976L32 45.6049L12.6031 59.6976L20.012 36.8951L0.615135 22.8024H24.591L32 0Z"
          fill="#EAEFF8"
        />
      </svg>
      <svg
        @click="setRating(4)"
        viewBox="-80 0 64 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32 0L39.409 22.8024H63.3849L43.988 36.8951L51.3969 59.6976L32 45.6049L12.6031 59.6976L20.012 36.8951L0.615135 22.8024H24.591L32 0Z"
          fill="#EAEFF8"
        />
      </svg>
      <svg
        @click="setRating(5)"
        viewBox="-160 0 64 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32 0L39.409 22.8024H63.3849L43.988 36.8951L51.3969 59.6976L32 45.6049L12.6031 59.6976L20.012 36.8951L0.615135 22.8024H24.591L32 0Z"
          fill="#EAEFF8"
        />
      </svg>
     <defs>
    <mask id="myMask" x="0" y="0" width="400" height="60">
      <svg
        viewBox="160 0 64 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32 0L39.409 22.8024H63.3849L43.988 36.8951L51.3969 59.6976L32 45.6049L12.6031 59.6976L20.012 36.8951L0.615135 22.8024H24.591L32 0Z"
          fill="#EAEFF8"
        />
      </svg>
      <svg
        viewBox="80 0 64 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32 0L39.409 22.8024H63.3849L43.988 36.8951L51.3969 59.6976L32 45.6049L12.6031 59.6976L20.012 36.8951L0.615135 22.8024H24.591L32 0Z"
          fill="#EAEFF8"
        />
      </svg>
      <svg
        viewBox="00 0 64 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32 0L39.409 22.8024H63.3849L43.988 36.8951L51.3969 59.6976L32 45.6049L12.6031 59.6976L20.012 36.8951L0.615135 22.8024H24.591L32 0Z"
          fill="#EAEFF8"
        />
      </svg>
      <svg
        viewBox="-80 0 64 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32 0L39.409 22.8024H63.3849L43.988 36.8951L51.3969 59.6976L32 45.6049L12.6031 59.6976L20.012 36.8951L0.615135 22.8024H24.591L32 0Z"
          fill="#EAEFF8"
        />
      </svg>
      <svg
        viewBox="-160 0 64 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32 0L39.409 22.8024H63.3849L43.988 36.8951L51.3969 59.6976L32 45.6049L12.6031 59.6976L20.012 36.8951L0.615135 22.8024H24.591L32 0Z"
          fill="#EAEFF8"
        />
      </svg>
    </mask>
     </defs>
      <rect ref="rect" x="0" y="0" width="400" height="60" fill="#F4CB49" mask="url(#myMask)"/>
  </svg>
</template>


<script>

export default {
    data() {
        return {
            rating: 1,
            mousedown: false,
        };
    },
    methods: {
        setRating(i){
            // console.log(i)
            this.rating = i
            const rect_ =this.$refs.rect
            rect_.setAttribute('width', (80*i))
        },
        getRating(){
          return this.rating
        }
    },
    mounted() {
        const svgElement =this.$refs.stars;
        const rect_ =this.$refs.rect;
        rect_.style["pointer-events"] = "none"

        svgElement.addEventListener('mousemove', function(event) {
            return
            // if(!this.mousedown) return
            const rect = svgElement.getBoundingClientRect();
            const x = event.clientX - rect.left;
            // console.log(x)


            // if (x >= 0 && x <= 400) {
            if (x >= 0 && x <= 480) {
                let x_ = x * 400 / 480
            // console.log('Mouse X:', x);
            rect_.setAttribute('width', x_)
            // You can use the x value here
            }
        });
    }

}
</script>

<style>
rect{
    transition: width 0.7s;
}
</style>