import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#ff8017",
                secondary: "#001f3f"
            },
            dark: {
              primary: '#00ff00',
              "text-primary": "#ffffffb3",
            //   primary: colors.blue.lighten3,
            //   secondary: "#1E1E1E" ,
              
            },
        }
    }
});