import Vue from 'vue'
import App from './components/App.vue';
import router from './router'
import vuetify from './plugins/vuetify';

import axios from 'axios';
import VueAxios from 'vue-axios';
import VModal from "vue-js-modal"

Vue.use(VueAxios, axios)
Vue.use(VModal)
Vue.config.productionTip = false

Vue.prototype.$routeToLoad = {
  route: {name: "Home"},
  set(route){
    this.route = route
    // this.route.query = {randomvar : new Date()}
  },
  get(){
    return this.route
  }
};

Vue.prototype.$navigation = {
  prev: {
    route: null,
    set(route){
      this.route = route
    },
    get(){
      return this.route
    }
  },
  next: {
    route: null,
    set(route){
      this.route = route
    },
    get(){
      return this.route
    }
  },
  customprev: {
    val: false,
    set(v){
      this.val = v
    },
    get(){
      return this.val
    }
  }
}

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
