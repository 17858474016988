<template>
  <div class="main" :style="image">
    <AdminPanel></AdminPanel>
    <div class="retour">
      <img 
      @click="goHome"
        :src="img.secondary.retour"
        draggable="false" 
      />
    </div>
    <div class="page-content page8">
      <img 
        class="mainImg"
        @click="pdfShow = true" 
        :src="img.secondary.image"
        draggable="false" 
      />
      <img 
        class="mainTxt"
        :src="img.secondary.texte"
        draggable="false" 
      />
      <img :src="require('@/assets/tests/expand.png')" style="position:absolute;left:820px;top:100px;" @click="pdfShow = true" />
      <div class="pdfviewer-container" v-show="pdfShow">
        <div class="close" :style="'background-image: url('+img.secondary.closebtn+');z-index:100'">
        </div>
        <div class="pdfviewer-bg" @click="exitPDF"></div>
        <div class="pdfviewer-wrapper" >
          <PSPDFKitContainerVue :url="url" :zoom="zoom" :height="height"></PSPDFKitContainerVue>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminPanel from "@/components/AdminPanel"
import PSPDFKitContainerVue from '../components/PSPDFKitContainer.vue';

export default {
  components: {
    AdminPanel,
    PSPDFKitContainerVue,
  },
  data() {
    return {
      pdfShow: false,
      url: './livretaccueil.pdf#toolbar=0&navpanes=0&scrollbar=0&scrollbar=0',
      zoom: 181,
      height: '190%',
      format: 'video/mp4',
      ready: false,
      img: {
        main: require("@/assets/pages/bg2.png"),
        secondary: {
          image: require("@/assets/pages/img8.png"),
          texte: require("@/assets/pages/txt8.png"),
          retour: require("@/assets/tests/retour.png"),
          closebtn: require('@/assets/img/close.svg')
        },
      },
    };
  },
  beforeMount() {},
  mounted() {
  },
  methods: {
    exitPDF(a){
      this.pdfShow = false
    },
    goto(route) {
      this.drawer = false;
      this.$routeToLoad.set(route);
      this.$router.push(route)
      // document.dispatchEvent(new CustomEvent("loadroute"));
    },
    goHome() {
      this.$routeToLoad.set({ name: "Home" });
      this.$router.push({ name: "Home" })
      // document.dispatchEvent(new CustomEvent("loadroute"));
    },
  },
  watch: {},
  computed: {
    image() {
      return `background-image:url(${this.img.main})`;
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  background-position-y: 100%;
  position: relative;
}

.buttons {
  position: absolute;
  bottom: 113px;
  // bottom: 97px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 124px;
}

.retour{
  position: absolute;
  left: 45px;
  z-index: 100;
  bottom: 770px;
}

.page-title{
  position: absolute;
  top: 10px;
  align-content: center;
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 106%;

  color: #3A4592;
  z-index: 30000;
}

.mainImg{
  position: absolute;
  left: 337px;
  bottom: 105px;
}
.mainTxt{
  position: absolute;
  right: 148px;
  bottom: 422px;
}
.mainVideo, video{
  height: 680px;
  width: 596px;
}

.pdfviewer-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  .pdfviewer-bg{
    width: 100%;
    height: 100%;
    position: relative;
    background: #3A4592;
    opacity: 0.8;
    // pointer-events: none;
  }

  .pdfviewer-wrapper{
    position: fixed;
    top: 56px;
    left: 0;
    z-index: 1100;
    margin: 0 250px;
    width: calc(100% - 500px);
    height: calc(100% - 56px);
  }
}

.close{
      position: absolute;
      top: 57px;
      right: 31px;
      width: 46px;
      background-size: contain;
      height: 48px;
      pointer-events: none;
    }


</style>
