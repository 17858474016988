<template>
<div>
<div v-show="ready" class="main accueil" @click="quit /*goHome*/" :touchstart="quit">
    <!-- <div class="mouse"></div> -->
    <video id="videoplayer" height="1080" width="1920" preload="auto" @click="quit" @touchstart="quit">
      <source :src="video" type="video/mp4" />
    </video>
    <!-- <div class="touchscreen" @click="quit" :style="secondary"></div> -->
  </div>
<div v-show="!ready" @click="quit" :touchstart="quit">
    <!-- <div class="mouse"></div> -->
    <!-- <div class="touchscreen" @click="quit" :style="secondary"></div> -->
  </div>

</div>
  
</template>

<script>
import * as videojs from 'video.js'
let videoJS = videojs.default
// console.log(videoJS)

export default {
  components: {},
  data() {
    return {
      videoplayer: null,
      video: require("@/../public/Film_Almaviva.mp4"),  //dev
      // video: 'local-video:///Transition_energetique.mp4', //prod
      ready: false,
      img: {
        main: require("@/assets/tests/accueil.png"),
        secondary: require("@/assets/tests/touch.png"),
      },
    };
  },
  beforeMount() {},
  mounted() {

    let t = this
    this.videoplayer = videoJS("videoplayer", {controls: true, muted: true, preload: 'auto', autoplay: true, loop: true}, function onPlayerReady()  {
      this.on('ready', function() {
        t.ready = true
        this.play();
      });
      this.on('click', function() {
        t.quit()
      });
    });
    
    // console.log(this.videoplayer)
  },
  methods: {
    goHome() {
      this.$routeToLoad.set({ name: "Home" });
      this.$router.push({name: "Home" })
    },
    quit(){
      this.$router.push("/")
      this.$emit('quit')
    }
  },
  watch: {},
  computed: {
    image() {
      return `background-image:url(${this.img.main})`;
    },
    secondary() {
      return `background-image:url(${this.img.secondary})`;
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  background-position-y: 100%;
  position: relative;
}

.touchscreen{
  width: 858px;
  height: 145px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 120px;
  z-index: 10000;
}

.accueil{
  z-index: 9999;
  position: fixed;
  top: 0;
}
</style>
