<template>
  <div class="b-container">
    <!-- <div @animationend="openOver" ref="right" class="right bande1"></div> -->
    <!-- <div @animationend="openOver" ref="right" class="right bande1"></div> -->
    <img v-show="bande1" :src="require('@/assets/tests/bandewhite.png')" ref="white" :class="bande2 ? '' : 'b-white-fix'" class="bande b-white" />
    <img v-show="bande2" :src="require('@/assets/tests/bandegrey.png')" ref="grey" class="bande b-grey"/>

    <!-- <button @click="open" style="position:fixed;z-index:1000;left:80%;top:40%;transform:translate(-50%, -50%);background: green; margin-right: 60px;height:30px;width:30px"> </button>
    <button @click="close" style="position:fixed;z-index:1000;left:80%;top:40%;transform:translate(-50%, -50%);background: red; margin-left: 60px;height:30px;width:30px"> </button> -->
    <!-- <div class="bande b-white__shadow"></div> -->
    <!-- <img
      :src="img"
      style="
        position: fixed;
        top: 23.5px;
        left: 22px;
        z-index: 5;
        pointer-events: none;
      "
    /> -->
    <!-- <div ref="left" class="border left"></div> -->
    <!-- <div @animationend="openOver" ref="right" class="right"></div> -->
    <!-- <div ref="bottom1" class="border bottomleft"></div> -->
    <!-- <div @animationend="closeOver" ref="bottom2" class="border bottomright"></div> -->

    <!-- <div @click="open" style="position:fixed; top: 50% ; background: blue; left: 33%;width: 50px;height:50px"></div>
    <div @click="close" style="position:fixed; top: 50% ; background: green; right: 33%;width: 50px;height:50px"></div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  mounted(){
    document.addEventListener("loadroute", this.load);
  },
  beforeDestroy(){
     document.removeEventListener("loadroute", this.load);
  },
  computed: {
    bande1(){
      return true
      return (
        this.$route.name != "DirectivesAnticipees"
      );
    },
    bande2(){
      return (
        this.$route.name == "Home" ||
        this.$route.name == "BoiteIdees" ||
        this.$route.name == "Contact" 
      );
    }
  },
  methods: {
    load(){
      if(!this.$route.params.id && this.$route.name == this.$routeToLoad.route.name) return
      else if(this.$route.params.id && this.$route.name == this.$routeToLoad.route && this.$route.params.id == this.$routeToLoad.route.params.id) return
      this.close()
    },
    openOver(e){
      if(e.animationName.includes("expandHeight")){
        
      }
    },
    closeOver(e){
      if(e.animationName.includes("shrinkWidth")){
        // console.log("transi cadre")
        setTimeout(this.open, 350)
      }
    },
    open(){
      let t = this
      // if(this.$route.name == 'Temp') t.$router.push(t.$routeToLoad.get()).then(document.dispatchEvent(new CustomEvent("routeloaded")))
      // else this.$router.replace({name: 'Temp'}).then(() => {t.$router.push(t.$routeToLoad.get()).then(document.dispatchEvent(new CustomEvent("routeloaded")))})
      t.$router.push(t.$routeToLoad.get()).then(document.dispatchEvent(new CustomEvent("routeloaded")))
      this.$refs.white.classList.add("open")
      this.$refs.grey.classList.add("open")
      this.$refs.white.classList.remove("close")
      this.$refs.grey.classList.remove("close")
    },
    close(){
      this.$refs.white.classList.add("close")
      this.$refs.grey.classList.add("close")
      this.$refs.white.classList.remove("open")
      this.$refs.grey.classList.remove("open")
    }
  }
};
</script>


<style lang="scss" scoped>

.b-container{
  position: absolute;
  width: 100vw;
  height: auto;
  top: 0px;
  top: -5px;
  left: 0;

  .bande{
    width: 100%;
    &.b-white{
      position: absolute;
      height: auto;
      width: 100%;
      height: 69px;
      z-index: 1;
      top: 0px;
      
      &.b-white-fix{
        top: -13px !important;
      }

      &.open{
        animation: translateDown 0.4s ease-in forwards;
      }
      &.close{
        animation: translateUp 0.2s ease-in 0.6s forwards;
      }
    }
    &.b-fix{
      margin-top: -10px;
    }
    
    &.b-grey{
      width: 100%;
      position: absolute;
      height: 107px;
      top: 21px;
      height: auto;

       &.open{
          animation: translateDown 0.4s ease-in forwards;
        }
        &.close{
          animation: translateUp 0.2s ease-in 0.6s forwards;
        }
    }
  }
}



.border{
    background: #E3000B;
    position: absolute;

    &.bottomleft{
        height: 5px;
        margin: 0 50% 0 23px;
        bottom: 18px;
        // left: 0;
        width: calc( 50% - 23px);
        right: 0;
        z-index: 15;

        &.open{
          animation: expandWidth 0.4s ease-in forwards;
        }
        &.close{
          animation: shrinkWidth 0.4s ease-in 0.4s forwards;
        }
    }
    &.bottomright{
        height: 5px;
        margin: 0 17px 0 50%;
        bottom: 18px;
        // right: 0;
        width: calc(50% - 17px);
        left: 0;
        z-index: 15;

        &.open{
          animation: expandWidth1 0.4s ease-in forwards;
        }
        &.close{
          animation: shrinkWidth1 0.4s ease-in 0.4s forwards;
        }
    }
    &.left{
        width: 5px;
        margin: 0 0 20px 23px;
        bottom: 0;
        height: calc( 100% - 20px);
        z-index: 15;
         &.open{
          animation: expandHeight 0.4s ease-in 0.4s backwards;
        }
        &.close{
          animation: shrinkHeight 0.4s ease-in forwards;
        }
    }
    &.right{
        width: 5px;
        margin: 0 17px 20px 0;
        height: calc( 100% - 20px);
        right: 0;
        bottom: 0;
        z-index: 150;
        &.open{
          animation: expandHeight 0.4s ease-in 0.4s backwards;
        }
        &.close{
          animation: shrinkHeight 0.4s ease-in forwards;
        }
    }
}

@keyframes expandWidth {
  0% {
    width: 0;
  }
  100% {
    width: calc( 50% - 23px);
  }
}

@keyframes shrinkWidth {
  0% {
    width: calc( 50% - 23px);
  }
  100% {
    width: 0;
  }
}
@keyframes expandWidth1 {
  0% {
    width: 0;
  }
  100% {
    width: calc( 50% - 17px);
  }
}

@keyframes shrinkWidth1 {
  0% {
    width: calc( 50% - 17px);
  }
  100% {
    width: 0;
  }
}

@keyframes expandHeight {
  0% {
    height: 0;
  }
  100% {
    height: calc( 100% - 20px);
  }
}

@keyframes shrinkHeight {
  0% {
    height: calc( 100% - 20px);
  }
  100% {
    height: 0;
  }
}


@keyframes translateDown {
  0% {
    transform: translate(0, -120px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@keyframes translateUp {
  0% {
    transform: translate(0, 0px);
  }
  100% {
    transform: translate(0, -120px);
  }
}

</style>