<template>
  <div
    ref="keyboard"
    style="padding: 20px 20px; background: white"
    class="simple-keyboard"
  ></div>
</template>

<script>
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";

export default {
  data() {
    return {
      keyboard: null,
      needMailInput: false,
      shiftOn: false,
    };
  },
  props: {
    mailKeys: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    onChange(input) {
    //   console.log("Input changed", input);
    },
    onKeyPress(button) {
      // console.log("Button pressed", button);
       if (button === "{shift}") this.handleShift();
       else if (button === "{space}") this.type(" ");
       else if (button === "{bksp}") this.bksp();
       else if (button === "{enter}") this.enter();
       else{
        this.type(button)
       }
    },
    type(key) {
      this.$emit("type", key);
    },
    bksp() {
      this.$emit("bksp");
    },
    enter() {
      this.$emit("enter");
    },
    reset() {
      this.keyboard.setInput("");
    },
    handleShift() {
        this.shiftOn = !this.shiftOn
        this.handleKeyboard()
    },
    // handleShift() {
    //     let currentLayout = this.keyboard.options.layoutName;
    //     let shiftToggle = currentLayout === "default" ? "shift" : "default";

    //     this.keyboard.setOptions({
    //         layoutName: shiftToggle
    //     });
    // },
    handleKeyboard(){
      // let currentLayout = this.keyboard.options.layoutName;
      let newLayout = "default";
      if(this.needMailInput){
        if(this.shiftOn) 
          newLayout = "shiftMail";
        else 
          newLayout = "defaultMail";
      }
      else{
        if(this.shiftOn) 
          newLayout = "shift";
        else 
          newLayout = "default";
      }

      requestAnimationFrame( () => this.keyboard.setOptions({
        layoutName: newLayout
      }))

      
    },
    needMailKeys(bool){
      this.needMailInput = bool
      this.handleKeyboard()
    }
  },
  mounted() {
    let t = this;
    this.keyboard = new Keyboard({
      layout: {
        default: [
          "1 2 3 4 5 6 7 8 9 0",
          "a z e r t y u i o p",
          "q s d f g h j k l -",
          "{shift} w x c v b n m _",
          "{bksp} {space} {enter}",
        ],
        shift: [
          "1 2 3 4 5 6 7 8 9 0",
          "A Z E R T Y U I O P",
          "Q S D F G H J K L -",
          "{shift} W X C V B N M _",
          "{bksp} {space} {enter}",
        ],
        defaultMail: [
          "1 2 3 4 5 6 7 8 9 0",
          "a z e r t y u i o p",
          "q s d f g h j k l -",
          "{shift} w x c v b n m _",
          "@hotmail.com @gmail.com @orange.fr",
          "{bksp} {space} {enter}",
        ],
        shiftMail: [
          "1 2 3 4 5 6 7 8 9 0",
          "A Z E R T Y U I O P",
          "Q S D F G H J K L -",
          "{shift} W X C V B N M _",
          "@hotmail.com @gmail.com @orange.fr",
          "{bksp} {space} {enter}",
        ],
      },
      display: {
        '{bksp}': '⌫',
        '{enter}': '⏎',
        '{space}': '␣',
        '{shift}': '⇧',
        },
      onChange: (input) => t.onChange(input),
      onKeyPress: (button) => t.onKeyPress(button),
      onInit: () => t.needMailKeys(t.mailKeys),
      // onRender: () => t.needMailKeys(t.mailKeys),
    });
  },
};
</script>
